import s from './Mission.module.css'
export const Mission = () => {
  return(
      <div className={s.Mission}>
          <div className={s.MissionBG}>
              <h3>Mission</h3>
          </div>
            <div className="container">
                <div className={s.Wrapper}>
                    <h4 id={'mission'} className={`${s.missonTitle} ${s.aboutTitles}`}>
                        Mission
                    </h4>
                    <p className={s.missionDesc}>
                        Avrasya Consulting's mission statement articulates its commitment to achieving its vision
                        and the approach it takes to make it a reality. The mission of Avrasya Consulting is
                        threefold:
                    </p>
                    <p className={s.missionInfo}>
                        <span> Connecting Exceptional Professionals with Companies Worldwide: </span>
                        At the core of Avrasya Consulting's mission is the commitment to identify and connect
                        top-tier professionals with organizations across the globe. The firm understands that the
                        success of any company relies on its human capital, and, as such, its primary mission is to
                        bridge this gap by meticulously matching the right talent with the right opportunities.
                        Avrasya Consulting's extensive network and cutting-edge sourcing techniques play a pivotal
                        role in fulfilling this mission. It prides itself on its ability to spot exceptional
                        candidates in a variety of industries and positions, transcending borders to ensure the
                        perfect fit.
                    </p>
                    <p className={s.missionInfo}>
                        <span> Emphasizing Cultural Fit: </span>
                        Avrasya Consulting recognizes that a successful recruitment process goes beyond just
                        matching skills; it involves finding individuals who seamlessly integrate into the culture
                        of the organization. This mission is particularly important in a global context where
                        differences in culture and work styles can either enhance or hinder a company's success.
                        Avrasya Consulting is dedicated to ensuring that the professionals it places not only
                        possess the requisite skills but also harmonize with the culture and values of the companies
                        they join.
                    </p>
                    <p className={s.missionInfo}>
                        <span> Delivering Personalized Service and Exceeding Expectations:  </span>
                        To achieve its vision, Avrasya Consulting is dedicated to providing a level of service that
                        goes above and beyond. The firm's consultants are experienced and committed to personalizing
                        their approach to each client. They understand that every organization has unique needs and
                        expectations, and they aim to exceed these expectations by delivering recruitment solutions
                        that are tailored to fit each client's specific requirements. Avrasya Consulting's mission
                        is not just to meet its clients' needs but to surpass them, leaving a lasting and positive
                        impact on the companies it serves.
                    </p>
                </div>
            </div>
      </div>
  )
}