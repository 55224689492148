import s from './CorporateResponsibility.module.css'
import {Link} from "react-router-dom";
import EnvironmentImg from '../../assets/img/CorporateResponsibility/EnvironmentImg.webp'
import EthicalImg from '../../assets/img/CorporateResponsibility/EthicalImg1.webp'
import CommunityImg from '../../assets/img/CorporateResponsibility/CommunityImg.webp'
import MentorImg from '../../assets/img/CorporateResponsibility/MentorImg.webp'
export const CorporateResponsibility = () => {
    return (
        <div className={s.CorporateResponsibility}>
            <div className={s.CorporateResponsibilityBG}>
                <h3>Corporate Responsibility</h3>
            </div>
            <div className="container">
                <div className={s.Wrapper}>
                    <div className={`${s.Flex} ${s.Flex2}`}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>Environmental responsibility</h5>
                            <p>
                                AVRASYA ensures the governance of its corporate responsibility policy to all its systems, operations, and employees. It comes with the obligations that all the utilized processes and operations are carried out with minimized environmental impacts. AVRASYA uses LEED standards as a basis for its projects, where energy, recycling, and carbon-footprint are monitored. Moreover, all daily operations are monitored in a similar manner to increase dependency on digital tools and minimize physical processes, allowing the facilitation of a high environmentally conscious policy.
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={EnvironmentImg} alt="Environment Img"/>
                        </div>
                    </div>
                    <div className={s.Flex}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>Ethical responsibility</h5>
                            <p>
                                Integrity, transparency, and fairness are among the basic elements of AVRASYA’s business operations.
                                Our <Link className={s.LinkUnderline} to={'/CodeOfConductPdf'}>Code of Conduct</Link> is based on these values for a fair competition and healthy business environment. Our policy is inclusive of health and safety, ethical, and legal processes that continuously evaluate the implementation of laws and regulations in our areas of operation and their alignment with our ethical standards.
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={EthicalImg} alt="Ethical responsibility"/>
                        </div>
                    </div>
                    <div className={`${s.Flex} ${s.Flex2}`}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>Philanthropic Responsibility</h5>
                            <p>
                                The global communities AVRASYA serves are at the heart of its vision and mission through providing opportunities to less-privileged communities to enhance their living standards. Moreover, AVRASYA provides aids programs to several countries around the globe, extending from West Africa to South eastern Asia. We dedicate parts of our profits to help those in need of education, healthcare, clean water, and basic amenities. At our home market, Türkiye, we help needed individuals and communities through our continuous communication with local charity organizations.
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={CommunityImg} alt="Philanthropic Responsibility"/>
                        </div>
                    </div>
                    <div className={s.Flex}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>Economic responsibility</h5>
                            <p>
                                As a business operating in the Turkish market, AVRASYA financial policy is alignment with the country’s strategies. We design our financial operations in a sustainable manner that can contribute to the well-being of the local communities. Future projects of AVRASYA should enable local workforce to perform more talented work that are imperative for a developed economy, as well as support domestic partners in performing business that contributes to the Turkish economy.
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={MentorImg} alt="Economic responsibility"/>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}