import s from './Services.module.css'
import {Link} from "react-router-dom";
import CostReduction from "../../assets/img/CostReduction/CostReductionBG.webp";
import StaffAccommodation from "../../assets/img/StaffAccommodation/StaffAccommodationBG.jpg";
import HrConsultancy from "../../assets/img/HrConsultancy/HrConsultancyBG.webp";
import Recruitment from '../../assets/img/Recruitment/RecruitmentBG.webp'
import StaffingManagement from '../../assets/img/StaffManagement/StaffManagementBG.webp'
import Hiring from '../../assets/img/360Hiring/360Hiring.webp'

export const Services = () => {
    return (
        <div className={s.Services}>
            <div className={s.ServicesBG}>
                <h3>Services</h3>
            </div>
            <div className="container">
                <h4 className={s.title}>Core Services</h4>
                <div className={s.parent}>
                    <Link to={'/CostReduction'} className={`${s.CostReduction} ${s.div1}`}>
                        <div>
                            <img src={CostReduction} alt="Cost Reduction"/>
                            <p>Cost Reduction</p>
                        </div>
                    </Link>
                    <Link to={'/StaffAccommodation'} className={`${s.StaffAccommodation} ${s.div2}`}>
                        <div>
                            <img src={StaffAccommodation} alt="Staff Accommodation"/>
                            <p>Staff Accommodation</p>
                        </div>
                    </Link>
                    <Link to={'/HrConsultancy'} className={`${s.HrConsultancy} ${s.div3}`}>
                        <div>
                            <img src={HrConsultancy} alt="HR Consultancy"/>
                            <p>HR Consultancy</p>
                        </div>
                    </Link>

                </div>

                <h4 className={s.title}>Corporate Support</h4>

                <div className={s.parent}>
                    <Link to={'/Recruitment'} className={`${s.CostReduction} ${s.div1}`}>
                        <div>
                            <img src={Recruitment} alt="Recruitment"/>
                            <p>Recruitment</p>
                        </div>
                    </Link>
                    <Link to={'/StaffingManagement'} className={`${s.StaffAccommodation} ${s.div2}`}>
                        <div>
                            <img src={StaffingManagement} alt="StaffingManagement"/>
                            <p>Staffing Management</p>
                        </div>
                    </Link>
                    <Link to={'/360Hiring'} className={`${s.HrConsultancy} ${s.div3}`}>
                        <div>
                            <img src={Hiring} alt="Hiring"/>
                            <p>360° Hiring</p>
                        </div>
                    </Link>

                </div>
            </div>

        </div>
    )
}