import s from './Locations.module.css'
import turkeyOffice from '../../assets/img/Locations/turkeyOffice.png'
import ukraineOffice from '../../assets/img/Locations/UkraineOffice.png'
import bishkekOffice from '../../assets/img/Locations/BishkekOffice.png'
import polandOffice from '../../assets/img/Locations/PolandOffice.png'
import {Link} from "react-router-dom";

export const Locations = () => {
    return (
        <div className={s.Locations}>
            <div className={s.LocationsBG}>
                <h3>Locations</h3>
            </div>
            <div className="container">
                <div className={s.Wrapper}>
                    <div className={s.mainOffice}>
                        <h4 className={s.Title}>Main office</h4>
                        <img src={turkeyOffice} alt="turkeyOffice"/>
                        <p className={s.city}>Ankara</p>
                        <p className={s.address}><Link className={s.addressLink} to={"https://maps.app.goo.gl/tsXEudDJRJmDcwfs7"}>Address: Anadolu Bulivari, No: 151/14, Yenimahalle, Ankara, Türkiye</Link> </p>
                        <p className={s.phone}>Tel: +90 312 543 83 38</p>
                        <p className={s.email}>Email: info@avrasyaconsulting.com</p>
                    </div>
                    <h4 className={s.regTitle}>Regional offices</h4>
                    <div className={s.regionalOffices}>
                        <div className={s.regOffice}>
                            <p className={s.regOfficeCity}>Kiev</p>
                            <img src={ukraineOffice} alt="ukraineOffice"/>
                            <p className={s.regOfficeAddress}>
                                <Link className={s.addressLink} to={"https://maps.app.goo.gl/khT1J6rnxnTvjRqf6"}>01133, Kiev, bul. Lesi Ukrainky, 28, of. 616 (Pecherskaya metro station)</Link> </p>
                        </div>
                        <div className={s.regOffice}>
                            <p className={s.regOfficeCity}>Bishkek</p>
                            <img src={bishkekOffice} alt="bishkekOffice"/>
                            <p className={s.regOfficeAddress}>
                               <Link className={s.addressLink} to={"https://maps.app.goo.gl/8Gz3mKMP4hJtXTsn8"}>Bishek city, 720031, Kulatova street 12</Link> </p>
                        </div>
                        <div className={s.regOffice}>
                            <p className={s.regOfficeCity}>Warsaw</p>
                            <img src={polandOffice} alt="polandOffice"/>
                            <p className={s.regOfficeAddress}><Link className={s.addressLink} to={"https://maps.app.goo.gl/1iZHFZ5MFaQ5XpBu8"}>Franciszka Klimczaka 17, 02-972 Warszawa</Link> </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}