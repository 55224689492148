import s from './EthicsCompliance.module.css'
import EthicsImg from '../../assets/img/EthicsCompliance/ethicsImg.webp'
import ComplianceImg from '../../assets/img/EthicsCompliance/ComplianceImg.webp'
import CulturalDifImg from '../../assets/img/EthicsCompliance/CulturalDifImg.webp'
import ProtectionImg from '../../assets/img/EthicsCompliance/ProtectionImg.webp'
import SupportImg from '../../assets/img/EthicsCompliance/SupportImg.webp'
export const EthicsCompliance = () => {
    return (
        <div className={s.EthicsCompliance}>
            <div className={s.EthicsComplianceBG}>
                <h3>Ethics + Compliance</h3>
            </div>
            <div className="container">
                <div className={s.Wrapper}>
                    <div className={s.Text}>
                        <h3 className={s.Title}>
                            Our Ethics and Compliance: The Key to Success in Employment Abroad
                        </h3>
                        <p className={s.Desc}>
                            In the world of international employment, "Our Ethics and Compliance" become the fundamental
                            principles that define our enterprise. Our title is not just a promise - it is a commitment
                            to our clients and candidates. We are convinced that true success in employment abroad is
                            only possible through careful adherence to the highest standards of ethics and strict
                            adherence to rules. These principles are our guide to creating strong and successful
                            partnerships based on mutual trust and respect.
                        </p>
                    </div>
                    <div className={s.Flex}>
                        <div className={s.FlexText}>
                            <h4 className={s.FlexTitle}>
                                Our Commitment to Ethics and Integrity
                            </h4>
                            <p>
                                We strive for the highest standards of ethics and integrity in the employment process.
                                Every client and applicant can be confident that we are guided by the principles of
                                integrity and transparency in all our business dealings.
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={EthicsImg} alt=""/>
                        </div>
                    </div>
                    <div className={`${s.Flex} ${s.Flex2}`}>
                        <div className={s.FlexText}>
                            <h4 className={s.FlexTitle}>
                                Compliance with Legal Norms and Standards
                            </h4>
                            <p>
                                Our services are based on strict compliance with the laws and regulations of the
                                countries where we provide employment opportunities. We provide advice and support on
                                all legal issues related to international employment.
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={ComplianceImg} alt="Compliance with Legal Norms and Standards"/>
                        </div>
                    </div>
                    <div className={s.Flex}>
                        <div className={s.FlexText}>
                            <h4 className={s.FlexTitle}>
                                Respect for Cultural Differences
                            </h4>
                            <p>
                                We value and respect the cultural diversity that our applicants represent. Our clients
                                can be confident that we create conditions for harmonious interaction in a multicultural
                                environment, ensuring equality and respect for differences.
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={CulturalDifImg} alt="Respect for Cultural Differences"/>
                        </div>
                    </div>
                    <div className={`${s.Flex} ${s.Flex2}`}>
                        <div className={s.FlexText}>
                            <h4 className={s.FlexTitle}>
                                Confidentiality of Information
                            </h4>
                            <p>
                                All personal data and information provided by us are treated as strictly trade secrets.
                                We guarantee complete confidentiality and security of the data of our clients and
                                applicants.
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={ProtectionImg} alt="Confidentiality of Information"/>
                        </div>
                    </div>
                    <div className={s.Flex}>
                        <div className={s.FlexText}>
                            <h4 className={s.FlexTitle}>
                                Support and Maintenance
                            </h4>
                            <p>
                                We don't just provide employment services; We provide support and accompaniment to our
                                clients at every stage. Our team is always ready to answer your questions and solve any
                                difficulties that arise.
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={SupportImg} alt="Support and Maintenance"/>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}