import s from './Recruitment.module.css'
import MarketTrendsImg from  '../../assets/img/Recruitment/MarketTrendsImg.webp'
import TalentMatchImg from  '../../assets/img/Recruitment/TalentMatchImg.webp'
import BusinessNetworkImg from '../../assets/img/Recruitment/BusinessNetworkImg.webp'
export const Recruitment = () => {
    return (
        <div className={s.Recruitment}>
            <div className={s.RecruitmentBG}>
                <h3>Recruitment</h3>
            </div>
            <div className="container">
                <div className={s.Wrapper}>
                    <div className={s.Text}>
                        <div className={s.TextInner}>
                            <p className={s.Desc}>
                                AVRASYA, a prominent player in the Turkish market, has strategically positioned itself as a leading force in the recruitment industry by adopting a comprehensive approach that not only addresses market demands but also aligns closely with the needs of employers. The organization's recruitment strategy revolves around a seamless integration of data-driven decision-making, talent acquisition, training, and meticulous compensation and payroll management.
                            </p>
                            <p className={s.DescSec}>
                                At the core of AVRASYA's recruitment strategy lies its ability to harness a continuous stream of data generated through its sustained presence in the Turkish market and its extensive communication with major employers. The market development team is pivotal in processing this data, allowing the company to make informed predictions regarding economic trends, development opportunities, and future employment needs. This valuable information serves as the foundation for directing global offices and partners to actively seek out and acquire specific talents that are anticipated to be in demand.
                            </p>
                            <p className={s.Desc}>
                                The collaboration between AVRASYA's sales and business development team and designated individuals or teams within client organizations is crucial in identifying employer demands. The Best-Talent-Match approach ensures that these demands are meticulously addressed, with a focus on finding the most suitable matches within pre-identified global talent zones. This targeted approach enhances the efficiency of the recruitment process, creating a win-win situation for both employers and candidates.
                            </p>
                            <p className={s.DescSec}>
                                Talent acquisition is facilitated by AVRASYA's extensive and regularly updated database, a key asset contributing to the company's success, competitiveness, and leadership in the Turkish market. With 27 resourcing locations spread across 19 countries on three continents, AVRASYA has established a robust global network. Candidates are rigorously evaluated through global testing centers, ensuring a high level of competency and a thorough understanding of their skill sets.
                            </p>
                            <p className={s.Desc}>
                                AVRASYA's commitment to talent development is exemplified by its focus on training centers, particularly in the Southeast Asia region. This strategic initiative aims to cultivate a pool of highly skilled talents that align with the economic and development goals of Turkey. By investing in training programs, AVRASYA not only addresses current talent needs but also contributes to the long-term growth and sustainability of the workforce.</p>
                        </div>
                        <div className={s.TextImgs}>
                            <img src={MarketTrendsImg} alt="Market Trends"/>
                            <img src={TalentMatchImg} alt="Taletn Match"/>
                            <img src={BusinessNetworkImg} alt="Business Network"/>
                            </div>
                    </div>

                </div>
            </div>

        </div>
    )
}