import s from './StaffingManagement.module.css'
import MarketDemandsImg from '../../assets/img/StaffManagement/MarketDemandsImg.webp'
import EmployerDemandsImg from '../../assets/img/StaffManagement/EmployerDemandImg.webp'
import TalentAcquisitionImg from '../../assets/img/StaffManagement/TalentAcquisitionImg.webp'
import TrainingImg from '../../assets/img/StaffManagement/TrainingImg.webp'
import PayrollManagementImg from '../../assets/img/StaffManagement/PayrollManagementImg.webp'
export const StaffingManagement = () => {
    return (
        <div className={s.StaffingManagement}>
            <div className={s.StaffingManagementBG}>
                <h3>Staffing Management</h3>
            </div>
            <div className="container">
                <div className={s.Wrapper}>
                    <div className={s.Flex}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>Market Demands</h5>
                            <p>
                                The continuous presence of AVRASYA in its home market and its communication with the largest employers in Türkiye creates a sustainable stream of data that is handled by the market development team. Through the collected data we are able to predict economic trends, development opportunities, and future employment needs for companies. Based on this data, AVRASYA directs its global offices and worldwide partners to feed the database with specific talents anticipated to be in demand.
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={MarketDemandsImg} alt="Market Demands"/>
                        </div>
                    </div>
                    <div className={`${s.Flex} ${s.Flex2}`}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>Employer Demands</h5>
                            <p>
                                AVRASYA’s sales and business development team is responsible for identifying the demands of employers through working closely with the designated individuals and teams. Moreover, resources are allocated based on the Best-Talent-Match approach, where demands are addressed in detail to find their matches in pre-identified global talent zones.
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={EmployerDemandsImg} alt="Empoyer Demands"/>
                        </div>
                    </div>
                    <div className={s.Flex}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>Talent Acquisition </h5>
                            <p>
                                The wide and continuously updated database of AVRASYA is a key factor in determining the company’s success, competitiveness, and leadership in the Turkish market. The database is created through a strong network of 27 resourcing locations in 19 countries within 3 continents. Candidates are also evaluated through our global testing centers that ensures a high level of competency.
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={TalentAcquisitionImg} alt="Talent Acquisition"/>
                        </div>
                    </div>
                    <div className={`${s.Flex} ${s.Flex2}`}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>Training </h5>
                            <p>
                                AVRASYA develops a larger pool of talent through training centers focused mainly on the Southeast Asia region. The aim of this strategy is to bring up the strongest killed talent database in Turkish market that can align with the economic and development goals of the country.
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={TrainingImg} alt="Training"/>
                        </div>
                    </div>
                    <div className={s.Flex}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>Compensation and Payroll Management</h5>
                            <p>
                                The staffing management processes of AVRASYA guarantees the best market rates for the sourced talents based on market value. Moreover, the company follows up with the Turkish Ministry of Labor and Social Security on the country’s compensation standards for foreign employment. AVRASYA also performs payroll management through its ADP-modified system upon request from employers for a reasonable fee.
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={PayrollManagementImg} alt="Payroll Management"/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}