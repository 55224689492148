import s from './Network.module.css'
import {Link} from "react-router-dom";

export const Network = () => {
    return (
        <div className={s.Network}>
            <div className={s.NetworkBG}>
                <h3>Network</h3>
            </div>
            <div className="container">
                <div className={s.Wrapper}>
                    <div className={s.Text}>
                        <p className={s.Desc}>
                            The operational network of AVRASYA is spread across three continents with our headquarters in Ankara, Türkiye. Regional offices are established in <span>Kiev</span>, <span>Warsaw</span>, <span>Bishkek</span>, and <span>Delhi.</span> Moreover, we have partner offices in more than 27 countries that we have established long-term trusted business with through our <Link to={"/Relationship-based"}>Relationship-Based</Link>  strategy. As an employer, you can rest assured that your workforce is resourced from the most diverse portfolio in the market, where you can find the precise talent to focus on the growth of your company. Our focus on the southeastern Asian and African regions provides us and our clients with a continuous manpower resources to cope up with the economical growth in Türkiye and Europe.
                        </p>
                    </div>

                </div>
            </div>

        </div>
    )
}