import s from './Hiring.module.css'
import TalentDatabaseImg from '../../assets/img/360Hiring/TalentDatabaseImg.webp'
import CandidatesImg from '../../assets/img/360Hiring/CandidatesImg.webp'
import HiringProcessImg from '../../assets/img/360Hiring/HiringProcessImg.webp'
import EmploymentSupportImg from '../../assets/img/360Hiring/EmploymentSupportImg.webp'
export const Hiring = () => {
    return (
        <div className={s.Hiring}>
            <div className={s.HiringBG}>
                <h3>360° Hiring</h3>
            </div>
            <div className="container">
                <div className={s.Wrapper}>
                    <div className={`${s.Flex} ${s.Flex2}`}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>Talent Database</h5>
                            <p>
                                With more than 6 years of operations, AVRASYA holds a strong talent database for blue-collar candidates that serve in multiple sectors including hospitality, construction, and manufacturing. We can provide skilled and unskilled workers in every discipline our employers need. Our database is designed to satisfy the performance requirements of companies in Türkiye to increase production, minimize costs, and enhance performance.
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={TalentDatabaseImg} alt="Talent Database"/>
                        </div>
                    </div>
                    <div className={s.Flex}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>Nominations and Filtering</h5>
                            <p>
                                After reviewing the candidates in our database, Employers can select those that fulfil their demands and filter those that they wish to employ. The filtering process includes maximizing the number of eligible candidates for the employers, handling the selection process with their specialized teams, testing of candidates at our equipped facilities worldwide, and interviewing candidates to ensure they meet the required standards.
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={CandidatesImg} alt="Candidates"/>
                        </div>
                    </div>
                    <div className={`${s.Flex} ${s.Flex2}`}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>Handling the Hiring Process</h5>
                            <p>
                                Following the nomination and filtration processes, AVRASYA handles all employment procedures from the preparation of work documents, the preparation of embassy applications abroad, to the extraction of work permits for the selected candidates from the ministry of labor in Türkiye. After a work visa and a work permit is issued to the candidate, AVRASYA ensures that they are met in the port of entry and delivered to their designated accommodation.
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={HiringProcessImg} alt="Hiring Process"/>
                        </div>
                    </div>
                    <div className={s.Flex}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>Post-Employment Support</h5>
                            <p>
                                Several points of attention can arise after the employment of the candidates, therefore, as part of AVRASYA’s 360° Hiring strategy, a customer support officer is designated to follow through on the concerns of both the employer and the candidate during the period of employment. We extend the furthest support regarding employment conditions, monitoring the fulfillment of employment contracts, and enhancing the work environment for both parties. After the agreed period of employment, AVRASYA handles the transfer or termination process of the employee to relief employers from any undesired consequences.
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={EmploymentSupportImg} alt="Employment Support"/>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}