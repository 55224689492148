import s from './CodeOfConduct.module.css'
import {Link} from "react-router-dom";
import LinkImg from '../../assets/img/CodeOfConduct/LinkImg.webp'
export const CodeOfConduct = () => {
    return (
        <div className={s.CodeOfProduct}>
            <div className={s.CodeOfProductBG}>
                <h3>Code of Conduct</h3>
            </div>
            <div className="container">
                <div className={s.Wrapper}>
                    <div className={s.Text}>
                        <h4 className={s.Title}>
                            Standards of conduct at Avrasya Consulting
                        </h4>
                        <p className={s.desc}>
                            At Avrasya Consulting, we adhere to high standards of ethical conduct and professional conduct.
                            These standards are aimed at creating a positive and productive work environment that encourages
                            teamwork and the achievement of common goals.
                        </p>
                        <h4 className={s.subtitle}>
                            Our Vision and Core Values are the foundation for this Code.
                        </h4>
                        <p className={s.subDesc}>
                            The following principles set forth the guidelines of behavior that we must follow:
                        </p>
                        <ul className={s.conductList}>
                            <li className={s.conductItem}>
                                Ethical behavior is the basis for everything we do.
                            </li>
                            <li className={s.conductItem}>
                                Avrasya Consulting is committed to treating its employees fairly and providing each of us
                                with opportunity for advancement and growth.
                            </li>
                            <li className={s.conductItem}>
                                Teamwork, mutual respect and trust, and open communication are the foundation of Avrasya
                                Consulting culture.
                            </li>
                            <li className={s.conductItem}>
                                Avrasya Consulting strives to be a responsible corporate citizen in the communities in which
                                we operate.
                            </li>
                            <li className={s.conductItem}>
                                Meeting clients' needs is of utmost importance.
                            </li>
                            <li className={s.conductItem}>
                                We are committed to compliance with the laws, rules and regulations applicable everywhere we
                                conduct business.
                            </li>
                        </ul>
                    </div>
                    <Link className={s.pdfLink} to={"/CodeOfConductPdf"}><img className={s.linkImg} src={LinkImg} alt="CodeOfConductPDF"/></Link>

                </div>
            </div>

        </div>
    )
}