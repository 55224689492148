import s from './RelationshipBased.module.css'
import RelationshipBasedImg from "../../assets/img/RelationshipBased/RelationshipBased.webp";

export const RelationshipBased = () => {
    return (
        <div className={s.RelationshipBased}>
            <div className={s.RelationshipBasedBG}>
                <h3>Relationship - Based</h3>
            </div>
            <div className="container">
                <div className={s.Wrapper}>
                    <div className={s.Text}>
                        <h4 className={s.Title}>
                            AVRASYA’s relationship-based model
                        </h4>
                        <p className={s.Desc}>
                            Our relationship-based model focuses on developing long-term relationships with customers, different from any competitors that are more transactional and short-termed focused. With about 80% of the company’s business coming from long-term relationships, AVRASYA likely will not be subject to the whims of higher risk projects in faraway places with unknown customers. Moreover, by continuing to emphasize these relationships, AVRASYA is able to minimize sales-related costs and generate very consistent sales growth.
                            <br/>
                            <br/>
                            AVRASYA’s Relationship-based approach has a strong influence on the way a business is handled. The approach gets us constant feedback from our clients, and importantly constructive feedback that allows us to continually address flaws in our services, methods, and processes. Our solutions is designed to align with our relationship-based approach and satisfy its needs. We provide pre-design strategies and design services for a range of public and private sector clients in Türkiye and globally.
                            <br/>
                            <br/>
                            Guided by our process-driven and strategic thinking, AVRASYA helps its clients achieve their objectives and goals and prepare for future growth. Our experience is comprehensive and diverse, with involvement in various aspects of planning – from the visioning and conceptual stages, to the technical, operational and financial elements. Collaborative by nature, AVRASYA relies on a relationship-based approach and value strong partnerships within the team to develop thorough and effective solutions.
                        </p>
                        <img className={s.descImg} src={RelationshipBasedImg} alt="Relationship - Based"/>
                    </div>

                </div>
            </div>

        </div>
    )
}