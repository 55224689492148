import s from './Footer.module.css'
import {Link} from "react-router-dom";

export const Footer = () => {
  return(
      <footer className={s.footer}>
          <div className="container">
              <div className={s.footerWrapper}>
                  <div className={s.footerText}>
                      <ul className={s.footer__links}>
                          <li><Link to={'/aboutUs'}>About Avrasya</Link></li>
                          <li><Link to={'/Ethics-Compliance'}>Ethics + Compliance</Link></li>
                          <li><Link to={'/Relationship-based'}>Relationship - based</Link></li>
                          <li><Link to={'/CodeOfConduct'}>Code of Conduct</Link></li>
                      </ul>
                      <ul className={s.footer__links}>
                          <li><Link to={'/Recruitment'}>Recruitment</Link></li>
                          <li><Link to={'/StaffingManagement'}>Staffing Management</Link></li>
                          <li><Link to={'/360Hiring'}>360° Hiring</Link></li>
                          <li><Link to={'/HrConsultancy'}>HR Consultancy</Link></li>
                      </ul>
                      <ul className={s.footer__links}>
                          <li><Link to={'/Solutions'}>Solutions</Link></li>
                          <li><Link to={'/Network'}>Network</Link></li>
                          <li><Link to={'/Locations'}>Locations</Link></li>
                      </ul>

                  </div>
                  <div className={s.footerLogo}>
                      <h3>© 2018 - 2024 Avrasya Consulting. <br/>All Rights Reserved.</h3>

                  </div>
              </div>
          </div>
      </footer>
  )
}