import s from './Purpose.module.css'
export const Purpose = ()=>{
    return  (
    <div className={s.Purpose}>
        <div className={s.PurposeBG}>
            <h3>Purpose</h3>
        </div>
        <div className="container">
            <div className={s.Wrapper}>
                <h4 id="purpose" className={`${s.purposeTitle} ${s.aboutTitles}`}>
                    Purpose
                </h4>
                <p className={s.purposeDesc}>
                    At the heart of its purpose, Avrasya Consulting is dedicated to facilitating connections
                    between top-tier professionals and companies worldwide. These connections are forged with an
                    unwavering commitment to excellence, trust, and a deep understanding of the diverse
                    industries and positions that make up the global job market. Avrasya Consulting's purpose is
                    a comprehensive commitment to excellence, trust, and global talent acquisition. It revolves
                    around creating meaningful connections, ensuring seamless recruitment solutions, and going
                    above and beyond to facilitate the success of both clients and candidates in a global
                    context. By doing so, the firm contributes to the growth and advancement of organizations
                    and individuals in an increasingly interconnected world.
                </p>
                <p className={s.purposeInfo}>
                    <span>Connecting Professionals and Companies: </span>Avrasya Consulting serves as a
                    bridge between highly
                    qualified professionals and organizations in need of their expertise. The firm
                    recognizes
                    that talent knows no geographical boundaries, and its primary purpose is to create
                    opportunities for professionals to explore global career prospects while helping
                    companies
                    access the best talent from around the world.</p>
                <p className={s.purposeInfo}>
                    <span>Excellence and Trust: </span>
                    Avrasya Consulting has built a reputation on the pillars of excellence
                    and trust. The firm's purpose is to maintain and strengthen this reputation by consistently
                    delivering high-quality recruitment services. This trust is not only with the clients but
                    also with the candidates, ensuring that the process is fair and transparent for all parties
                    involved.
                </p>
                <p className={s.purposeInfo}>
                    <span>International Talent Acquisition: </span>
                    Avrasya Consulting specializes in international talent
                    acquisition, emphasizing the global nature of its mission. The purpose is to identify and
                    attract talent from diverse corners of the world, acknowledging that a broad talent pool can
                    bring unique perspectives, skills, and experiences to the clients.
                </p>

                <p className={s.purposeInfo}>
                    <span>Extensive Network and Cutting-edge Techniques: </span>
                    The firm's consultants use their extensive
                    network and state-of-the-art sourcing techniques to fulfill their purpose. They understand
                    the importance of staying up-to-date with the latest recruitment trends and technologies,
                    enabling them to identify and engage exceptional candidates efficiently and effectively.
                </p>

                <p className={s.purposeInfo}>
                    <span> Cultural Fit: </span>
                    Avrasya Consulting recognizes the significance of cultural fit in the
                    recruitment process. It aims to align the values, behaviors, and work ethics of the
                    candidates with the culture of the hiring organization. This purpose ensures that candidates
                    not only have the necessary skills but can also seamlessly integrate into the company's work
                    environment.
                </p>

                <p className={s.purposeInfo}>
                    <span>Seamless Recruitment Solutions: </span>
                    One of the key purposes of the firm is to provide
                    recruitment solutions that transcend borders. This means simplifying the complexities
                    associated with hiring talent from different parts of the world, be it addressing legal
                    requirements, visa processes, or cultural nuances. Avrasya Consulting's purpose is to make
                    the entire recruitment process as smooth as possible for its clients.
                </p>

                <p className={s.purposeInfo}>
                    <span>Personalized Service: </span>
                    Avrasya Consulting is committed to offering personalized services. The
                    purpose here is to understand the unique needs of each client and candidate and tailor
                    recruitment solutions accordingly. This personal touch sets the firm apart and ensures that
                    clients receive a level of service that goes beyond the one-size-fits-all approach.
                </p>

                <p className={s.purposeInfo}>
                    <span>Exceeding Expectations: </span>
                    Beyond meeting the basic requirements of connecting professionals
                    with companies, Avrasya Consulting aims to exceed expectations. This means going the extra
                    mile to ensure the satisfaction of both candidates and clients, whether it's by offering
                    additional support, advice, or by constantly improving its services.
                </p>

                <p className={s.purposeInfo}>
                    <span> Navigating the Global Talent Landscape: </span>
                    Avrasya Consulting's overarching purpose is to be a
                    trusted partner for its clients in navigating the global talent landscape. In an
                    increasingly interconnected world, the firm is dedicated to helping organizations tap into
                    the vast reservoir of global talent, offering them a competitive edge.
                </p>

                <p className={s.purposeInfo}>
                    <span>Ensuring Organizational Success on a Global Scale: </span>
                    Ultimately, Avrasya Consulting's purpose
                    boils down to ensuring the success of its client organizations on a global scale. By
                    providing access to the best talent, promoting cultural fitness, and simplifying the
                    complexities of international recruitment, the firm plays a pivotal role in helping
                    companies thrive and prosper in a competitive and globalized business environment.
                </p>
            </div>
        </div>
    </div>


    )
}