import s from './AboutUs.module.css'
import purpose from '../../assets/img/aboutUs/purpose.webp'
import history from '../../assets/img/aboutUs/history.webp'
import vision from '../../assets/img/aboutUs/vision.webp'
import mission from '../../assets/img/aboutUs/mission.webp'
import {Link} from "react-router-dom";

export const AboutUs = () => {
    return (
        <div className={s.about}>
            <div className={s.aboutBG}>
                <h3>About us</h3>
            </div>
            <div className="container">
                <div className={s.aboutWrapper}>
                    <div className={s.aboutText}>
                        <p className={s.aboutTitle}>
                            Avrasya Consulting is a leading global recruitment firm specializing in international talent
                            acquisition. With a reputation built on excellence and trust, we connect top-tier
                            professionals with companies around the world. Our team of experienced consultants utilizes
                            an extensive network and cutting-edge sourcing techniques to identify exceptional candidates
                            for diverse industries and positions.
                            We understand the importance of cultural fitness and
                            aim to provide seamless recruitment solutions that transcend borders. Committed to
                            delivering personalized service and exceeding expectations, Avrasya Consulting is your
                            trusted partner in navigating the global talent landscape, ensuring your organization’s
                            success on a global scale.
                        </p>
                    </div>
                </div>

                <div className={s.parent}>
                    <Link to={'/Purpose'}  className={`${s.purpose} ${s.div1}`}>
                        <div>
                            <img src={purpose} alt=""/>
                            <p>Purpose</p>
                        </div>
                    </Link>
                    <Link to={'/History'} className={`${s.history} ${s.div2}`}>
                        <div >
                            <img src={history} alt=""/>
                            <p>History</p>
                        </div>
                    </Link>
                    <Link to={'/Vision'} className={`${s.vision} ${s.div3}`}>
                        <div>
                            <img src={vision} alt=""/>
                            <p>Vision</p>
                        </div>
                    </Link>
                    <Link to={'/Mission'} className={`${s.mission} ${s.div4}`}>
                        <div >
                            <img src={mission} alt=""/>
                            <p>Mission</p>
                        </div>
                    </Link>
                </div>

            </div>
        </div>


)
}