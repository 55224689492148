import {Slider} from "../../components/Slider/Slider";
import {Link} from "react-router-dom";
import careerPath from "../../assets/img/career.jpg";
import project from "../../assets/img/project.jpg";
import report from "../../assets/img/report.jpg";
import news from "../../assets/img/news.jpg";
import news2 from "../../assets/img/news2.jpg";
import {MediaCard} from "../../components/MediaCard/MediaCard";
import instagram from "../../assets/img/instagram.webp";
import linkedin from "../../assets/img/linkedin.jpg";
import './Main.css'

export const Main = () => {
    return (
        <div className="main">
            <Slider/>
            <div className="about">
                <div className="container">
                    <div className="about__wrapper">
                        <div className="about__desc">Avrasya Consulting is a leading global recruitment firm
                            specializing in international talent acquisition. With a reputation built on excellence and
                            trust, we connect professionals with companies around the world.
                        </div>
                        <div className="about__link"><Link to={'/aboutUs'}>About us <span>❯</span></Link></div>

                    </div>
                </div>
                <div className="parent">
                    <div className="parent__wrapper">
                        <Link className="career-path div1" to={'/Relationship-based'}>
                            <div>
                                <img src={careerPath} alt=""/>
                                <div className="grid__text">
                                    <span>Relationship - based</span>
                                    <p>Establishing long-term connections based on mutual trust</p>
                                </div>
                            </div>
                        </Link>
                        <Link className="project div2" to={'/Ethics-Compliance'}>
                            <div>
                                <img src={project} alt=""/>
                                <div className="grid__text">
                                    <span>Ethics + Compliance</span>
                                    <p>It's not what we do, it's HOW we do it</p>
                                </div>
                            </div>
                        </Link>
                        <Link className="report div3" to={'/Network'}>
                            <div>
                                <img src={report} alt=""/>
                                <div className="grid__text">
                                    <span>Network</span>
                                    <p>We are at doorstep of your business</p>
                                </div>
                            </div>
                        </Link>
                        <Link className="news div4" to={'/360Hiring'}>
                            <div>
                                <img src={news} alt=""/>
                                <div className="grid__text">
                                    <span>360° Hiring</span>
                                    <p>We take care of your recruitment process from A to Z</p>
                                </div>
                            </div>
                        </Link>
                        <Link className="news-2 div5" to={'https://portal.avrasyaconsulting.com/login'}>
                            <div>
                                <img src={news2} alt=""/>
                                <div className="grid__text">
                                    <span>AVRASYA Portal</span>
                                    <p>Your ultimate hiring connection</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="media">
                    <div className="container">
                        <div className="media__inner">
                            <MediaCard img={linkedin} title={'Join our professional network'}
                                       desc={''}
                                       mediaTitle={"Linkedin"}
                                       link={"https://www.linkedin.com/company/avrasya-consulting"}
                                       linkTitle={"Follow us"}
                            />
                            <MediaCard img={instagram} title={'Discover our community'}
                                       desc={''}
                                       mediaTitle={"Instagram"}
                                       link={"https://www.instagram.com/myvi.sa/"}
                                       linkTitle={"Learn more"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
