import s from './History.module.css'
export const History = () => {
  return (
      <div className={s.History}>
          <div className={s.HistoryBG}>
              <h3>History</h3>
          </div>
            <div className="container">
                <div className={s.Wrapper}>
                    <h4 id="history" className={`${s.historyTitle} ${s.aboutTitles}`}>
                        History
                    </h4>
                    <p className={s.historyDesc}>
                        Avrasya Consulting is on a pioneering journey to bring professional international
                        recruitment into the global market. Since its inception in 2018, the company has evolved
                        into a key player in the Turkish and European market. In its first year of operation,
                        Avrasya Consulting was focused on the manufacturing sector in Eastern Europe.
                    </p>
                    <p className={s.historyDesc}>
                        Strategic
                        partnerships were created with several industrial partners. A small number of employees were
                        provided that year, but the devoted commitment that was provided by the management
                        facilitated its success. Currently, Avrasya Consulting operates in more than 20 countries
                        around the world and provides employment to three sectors from its head office in Turkiye.
                        With our team of experts, we ensure that a steady growth is achieved based on trust and
                        track records.
                    </p>
                </div>
            </div>
      </div>
  )

}