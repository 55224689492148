import s from './Individuals.module.css'
import IndividualsImg from "../../assets/img/Individuals/Individuals.webp";


export const Individuals = () => {
    return (
        <div className={s.Individuals}>
            <div className={s.IndividualsBG}>
                <h3>Individuals</h3>
            </div>
            <div className={s.Wrapper}>
                <div className={s.Text}>
                    <h4 className={s.Title}>
                        Individuals
                    </h4>
                    <p className={s.Desc}>
                        The business model of AVRASYA does not currently allow for individual applicants to submit their applications. It is advised that you contact one of our regional offices for your submission.
                    </p>
                    <img className={s.descImg} src={IndividualsImg} alt="Individuals"/>
                </div>
            </div>
        </div>
    )
}