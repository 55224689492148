import './Header.css'
import {Link} from "react-router-dom";
import logo from '../../assets/img/logo.png'
import aboutUsLogo from '../../assets/img/AboutUsLogo.jpg'
import employers from '../../assets/img/employers.jpg'
import closeBtn from '../../assets/img/close-btn.svg'
import burgerMenu from '../../assets/img/burger-menu.svg'
import OurValues from '../../assets/img/OurValues.jpg'
import CoreServices from '../../assets/img/CoreServices.webp'
import CorporateSupport from '../../assets/img/CorporateSupport.webp'
import Hospitality from '../../assets/img/Hospitality.webp'
import Manufacturing from '../../assets/img/Manufacturing.webp'
import Construction from '../../assets/img/Construction.webp'
import avrasyaPortal from '../../assets/img/avrasyaPortal.png'
import {useTranslation} from "react-i18next";


export const Header = () => {

    const {t} = useTranslation()

    return (
        <header className="header">
            <nav>
                <div className="wrapper">
                    <Link to={'/'} className="logo"><img src={logo} alt="logo"/> <p>Avrasya <br/><span>Consulting</span>
                    </p></Link>
                    <input type="radio" name="slider" id="menu-btn"/>
                    <input type="radio" name="slider" id="close-btn"/>
                    <ul className="nav-links">
                        <label htmlFor="close-btn" className="btn close-btn">
                            <i className="fas fa-times"><img src={closeBtn} alt=""/></i></label>
                        <li>
                            <Link className="desktop-item" to={"/aboutUs"}>About Us</Link>
                            <input type="checkbox" id="aboutUs"/>
                            <label htmlFor="aboutUs" className="mobile-item">About Us</label>
                            <div className="mega-box">
                                <div className="content">
                                    <div className="row">
                                        <img src={aboutUsLogo} alt=""/>
                                    </div>
                                    <div className="row">
                                        <header>About Avrasya</header>
                                        <ul className="mega-links">
                                            <li><Link to={"/Purpose"}>Purpose</Link></li>
                                            <li><Link to={"/History"}>History</Link></li>
                                            <li><Link to={"/Vision"}>Vision</Link></li>
                                            <li><Link to={"/Mission"}>Mission</Link></li>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <img src={OurValues} alt="OurValues"/>
                                    </div>
                                    <div className="row">
                                        <header>Our Values</header>
                                        <ul className="mega-links">
                                            <li><Link to={"/Ethics-Compliance"}>Ethics + Compliance</Link></li>
                                            <li><Link to={"/Relationship-based"}>Relationship - based</Link></li>
                                            <li><Link to={"/CodeOfConduct"}>Code of Conduct</Link></li>
                                            <li><Link to={"/CorporateResponsibility"}>Corporate Responsibility</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <Link className="desktop-item" to={"/Services"}>Services</Link>
                            <input type="checkbox" id="services"/>
                            <label htmlFor="services" className="mobile-item">Services</label>
                            <div className="mega-box">
                                <div className="content">
                                    <div className="row">
                                        <img src={CoreServices} alt="CoreServices"/>
                                    </div>
                                    <div className="row">
                                        <header>Core Services</header>
                                        <ul className="mega-links">
                                            <li><Link to={"/Recruitment"}>Recruitment</Link></li>
                                            <li><Link to={"/StaffingManagement"}>Staffing Management</Link></li>
                                            <li><Link to={"/360Hiring"}>360° Hiring</Link></li>

                                        </ul>
                                    </div>
                                    <div className="row">
                                        <ul className="mega-links">
                                            <img src={CorporateSupport} alt="CorporateSupport"/>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <header>Corporate Support</header>
                                        <ul className="mega-links">
                                            <li><Link to={"/CostReduction"}>Cost Reduction</Link></li>
                                            <li><Link to={"/StaffAccommodation"}>Staff Accommodation</Link></li>
                                            <li><Link to={"/HrConsultancy"}>HR Consultancy</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <Link className="desktop-item" to={'/OurReferences'}>Our References</Link>
                            <input type="checkbox" id="references"/>
                            <label htmlFor="references" className="mobile-item">References</label>
                            <div className="mega-box">
                                <div className="content">

                                    <div className="row">
                                        <header className="references-header">Hospitality</header>
                                        <ul className="mega-links">
                                            <Link to={'/Hospitality'}>
                                                <img src={Hospitality} alt="Hospitality"/>
                                            </Link>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <header className="references-header">Manufacturing</header>
                                        <ul className="mega-links">
                                            <Link to={'/Manufacturing'}>
                                                <img src={Manufacturing} alt="Manufacturing"/>
                                            </Link>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <header className="references-header">Construction</header>
                                        <ul className="mega-links">
                                            <Link to={'/Construction'}>
                                                <img src={Construction} alt="Construction"/>
                                            </Link>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <Link className="desktop-item" to={"#"}>Corporate</Link>
                            <input type="checkbox" id="corporate"/>
                            <label htmlFor="corporate" className="mobile-item">Corporate</label>
                            <div className="mega-box">
                                <div className="content">
                                    <div className="row">
                                        <img src={employers} alt=""/>
                                    </div>
                                    <div className="row">
                                        <header>Employers</header>
                                        <ul className="mega-links">
                                            <li><Link to={"/Solutions"}>Solutions</Link></li>
                                            <li><Link to={"/DemandForm"}>Demand Form</Link></li>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <header>Agent</header>
                                        <ul className="mega-links">
                                            <li><Link to={"/Network"}>Network</Link></li>
                                            <li><Link to={"/AgentForm"}>Agent form</Link></li>
                                        </ul>
                                    </div>
                                    <div className="row">
                                        <ul className="mega-links">
                                            <li className="avrasya-portal">
                                                <Link to={'https://portal.avrasyaconsulting.com/login'}><img src={avrasyaPortal} alt="Portal"/></Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <Link className="desktop-item" to={"#"}>Contact Us</Link>
                            <input type="checkbox" id="contacts"/>
                            <label htmlFor="contacts" className="mobile-item">Contacts</label>
                            <ul className="drop-menu">
                                <li><Link to={"/Individuals"}>Individuals</Link></li>
                                <li><Link to={"/Locations"}>Locations</Link></li>
                            </ul>
                        </li>
                        <li>
                            <select className="language-select" name="language" id="">
                                <option value="EN">EN</option>
                                <option value="TR">TR</option>
                                <option value="RU">RU</option>
                            </select>
                        </li>
                    </ul>
                    <label htmlFor="menu-btn" className="btn menu-btn"><img src={burgerMenu} alt=""/><i
                        className="fas fa-bars"></i></label>
                </div>
            </nav>
        </header>
    )
}