import s from "../CostReduction/CostReduction.module.css";
import infographic from "../../assets/img/CorporateSupport/CostReductionGraphic.webp";
export const CostReduction = () => {
  return (
      <div>
          <div className={s.CostReduction}>
              <div className={s.CostReductionBG}>
                  <h3>Cost Reduction</h3>
              </div>
          </div>
          <div className="container">
              <div className={s.Wrapper}>
                  <div className={s.Text}>
                      <h4 className={s.Title}>AVRASYA achieves recruitment for its clients with the most reduction strategies used in HR
                          management.</h4>
                      <img className={s.costReductionImg} src={infographic} alt="infographic"/>
                  </div>
              </div>
          </div>
      </div>
  )
}