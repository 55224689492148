import React from "react";
import SwiperCore, {EffectCoverflow, Pagination, Autoplay} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import './Slider.css'
import {Link} from "react-router-dom";

SwiperCore.use([EffectCoverflow, Pagination, Autoplay]);


export const Slider = () => {
    return (
        <div className="slider-wrapper">
            <Swiper
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                pagination={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className='swiper-background swiper-background_1'>
                        <div className="swiper-text">
                            <h3 className="swiper-title">Our references </h3>
                            <p className="swiper-desc">As strong portfolio of employers across several sectors.</p>
                            <Link className="swiper-link" to={'/OurReferences'}>Learn more</Link>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='swiper-background swiper-background_2'>
                        <div className="swiper-text">
                            <h3 className="swiper-title">360° Hiring</h3>
                            <p className="swiper-desc">We understand your demand ... We fulfill it <br/> No hustle in between.</p>
                            <Link className="swiper-link" to={'/360Hiring'}>Learn more</Link>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='swiper-background swiper-background_3'>
                        <div className="swiper-text">
                            <h3 className="swiper-title">Our services </h3>
                            <p className="swiper-desc">Recruitment, Staffing Management and Corporate Support.</p>
                            <Link className="swiper-link" to={'/Services'}>Learn more</Link>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='swiper-background swiper-background_4'>
                        <div className="swiper-text">
                            <h3 className="swiper-title">Opportunities </h3>
                            <p className="swiper-desc">All your hiring needs a click away.</p>
                            <Link className="swiper-link" to={'/Portal'}>Learn more</Link>
                        </div>

                    </div>
                </SwiperSlide>

            </Swiper>
        </div>
    );
};