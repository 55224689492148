import s from './Vision.module.css'
export const Vision = () => {
  return  (
      <div className={s.Vision}>
          <div className={s.VisionBG}>
              <h3>Vision</h3>
          </div>
          <div className="container">
              <div className={s.Wrapper}>
                  <h4 id="vision" className={`${s.visionTitle} ${s.aboutTitles}`}>
                      Vision
                  </h4>
                  <h5 className={s.visionSubtitle}>Empowering Global Success Through Talent Integration</h5>
                  <p className={s.visionDesc}>
                      Avrasya Consulting's vision encapsulates its aspirations and long-term goals. The firm
                      envisions a world where businesses thrive by leveraging the full potential of global talent.
                      It is a world where geographical boundaries are no longer barriers but opportunities, and
                      where companies harness the richness of diverse perspectives and skill sets. Avrasya
                      Consulting envisions playing a pivotal role in creating this world by continuing to excel in
                      the field of international talent acquisition. In this vision, Avrasya Consulting sees
                      itself as a catalyst for global success, empowering organizations to reach new heights by
                      connecting them with the finest professionals from various corners of the world. It aims to
                      be at the forefront of innovation and excellence in recruitment, always adapting to the
                      changing dynamics of the global workforce to provide unparalleled service to its clients.
                      The firm envisions a future where cultural diversity and inclusivity are not just buzzwords,
                      but integral components of business strategies, and Avrasya Consulting intends to be a key
                      driver in making this vision a reality.
                  </p>
              </div>
          </div>

      </div>
  )
}