import s from './Solutions.module.css'
import {Link} from "react-router-dom";
import RecruitmentImg from '../../assets/img/Solutions/RecruitmentImg.webp'
import StaffingSolutionsImg from '../../assets/img/Solutions/StaffingSolutionsImg.webp'
import LogisticSolutionsImg from '../../assets/img/Solutions/LogisticSolutionImg.webp'
export const Solutions = () => {
    return (
        <div className={s.Solutions}>
            <div className={s.SolutionsBG}>
                <h3>Solutions</h3>
            </div>
            <div className="container">
                <div className={s.Wrapper}>

                    <div className={s.Flex}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>Recruitment Solutions</h5>
                            <p>
                                By adopting a holistic approach, the organization not only caters to market demands but also closely aligns with the needs of employers. Central to <Link to={'/Recruitment'}>AVRASYA's recruitment</Link>  strategy is its adept use of data-driven decision-making, talent acquisition, training, and meticulous compensation and payroll management. The Best-Talent-Match approach ensures meticulous addressing of these demands, focusing on finding suitable matches within pre-identified global talent zones, thereby enhancing the recruitment process's efficiency. Talent acquisition is facilitated by AVRASYA's extensive and regularly updated database, a key asset contributing to the company's success and leadership in the Turkish market. With a robust global network spanning 27 resourcing locations across 19 countries on three continents, candidates are rigorously evaluated through global testing centers, ensuring high competency and a thorough understanding of their skill sets.
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={RecruitmentImg} alt="Recruitment"/>
                        </div>
                    </div>
                    <div className={`${s.Flex} ${s.Flex2}`}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>Staffing solutions</h5>
                            <p>
                                AVRASYA, a staffing solutions provider with over 6 years of expertise, boasts an extensive Talent Database catering to blue-collar candidates across diverse sectors like hospitality, construction, and manufacturing in Türkiye with a <Link to={'/360Hiring'}>360° Hiring approach</Link>. AVRASYA assumes responsibility for the entire hiring process post-nomination and filtration. This encompasses document preparation, embassy applications, and securing work permits from Türkiye's Ministry of Labor. From welcoming candidates at the port of entry to ensuring their placement in designated accommodations, AVRASYA streamlines the entire journey. Emphasizing a comprehensive approach, AVRASYA extends post-employment support through a designated customer support officer. This 360° Hiring strategy addresses the concerns of both employers and candidates during the employment period. AVRASYA ensures compliance with employment contracts, monitors working conditions, and facilitates a smooth transfer or termination process after the agreed employment period, safeguarding both parties from undesired consequences.
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={StaffingSolutionsImg} alt="Staffing Solutions"/>
                        </div>
                    </div>
                    <div className={s.Flex}>
                        <div className={s.FlexText}>
                            <h5 className={s.FlexTitle}>Logistic solutions</h5>
                            <p>
                                AVRASYA's logistic solutions cater to the diverse <Link to={"/StaffAccommodation"}>staff accommodation</Link>  needs of businesses, offering both low-scale and large-scale options tailored to the specific requirements of employers. The company is committed to providing efficient solutions that consider factors such as location, cost feasibility, and timeframe commitments.
                            </p>
                        </div>
                        <div className={s.FlexImg}>
                            <img src={LogisticSolutionsImg} alt="Logistic Solutions"/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}