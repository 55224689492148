import s from "../StaffAccommodation/StaffAccommodation.module.css";
import solution1 from "../../assets/img/CorporateSupport/solution1.webp";
import solution2 from "../../assets/img/CorporateSupport/solution2.webp";
import solution3 from "../../assets/img/CorporateSupport/solution3.webp";

export const StaffAccommodation = () => {
  return (
      <div>
          <div className={s.StaffAccommodation}>
              <div className={s.StaffAccommodationBG}>
                  <h3>Staff Accommodation</h3>
              </div>
          </div>
          <div className="container">
              <div className={s.Wrapper}>
                  <div className={`${s.Flex} ${s.FlexColumn}`}>
                      <div className={s.FlexText}>
                          <h5 className={s.FlexTitle}>Low-scale solutions</h5>
                          <p>
                              AVRASYA provides low-scale accommodation solutions for a minimum of 10 employees and a maximum of 30 employees. The solutions depend on the location of employer facilities, cost feasibility, and timeframe commitments.
                          </p>
                      </div>
                      <div className={`${s.FlexImg} ${s.FlexImgDouble}`}>
                          <img src={solution1} alt="solution1"/>
                          <img src={solution2} alt="solution2"/>

                      </div>
                  </div>
                  <div className={`${s.Flex} ${s.Flex2}`}>
                      <div className={s.FlexText}>
                          <h5 className={s.FlexTitle}>Large-scale solutions</h5>
                          <p>
                              An under-development project by AVRASYA in strategic locations around Türkiye. Each of the developing 5 facilities has a starting capacity of 250 people and reaching to 1000 people at its most development. Employers can follow up with AVRASYA on their needs and development phases of the project
                          </p>
                      </div>
                      <div className={s.FlexImg}>
                          <img src={solution3} alt="solution3"/>
                      </div>
                  </div>
              </div>
          </div>
      </div>

  )
}